import React from 'react';
import classNames from 'classnames';
import { useVendorContext } from '../vendorContext';
import { usePathname } from 'next/navigation';
import { useScreenSize } from '@/hooks';

interface BackgroundThemeProps {
  children: React.ReactNode;
}

const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

const BackgroundTheme: React.FC<BackgroundThemeProps> = ({ children }) => {
  const path = usePathname();
  const { isMobile } = useScreenSize();
  const { vendor, vendorTheme } = useVendorContext();

  const patternImage = isMobile
    ? `${vendor?.vendor_storefront?.pattern_type}_200.webp`
    : `${vendor?.vendor_storefront?.pattern_type}.webp`;

  const isGradient =
    vendorTheme?.colors.background?.includes('linear') || vendorTheme?.colors.background?.includes('radial');
  const isPattern =
    vendor?.vendor_storefront?.pattern_type && vendor?.vendor_storefront?.pattern_type !== null;

  const backgroundStyles: React.CSSProperties = {
    ...(isGradient
      ? { background: vendorTheme?.colors.background }
      : {
          backgroundColor: vendorTheme?.colors.background || '#F1ECE8'
        }),
    ...(isPattern
      ? {
          backgroundImage: `url(${IMAGE_CDN_URL}/general/patterns/${patternImage})`,
          backgroundRepeat: 'repeat',
          backgroundSize: 'auto'
        }
      : {})
  };

  return (
    <div className="flex-1" style={backgroundStyles}>
      {children}
    </div>
  );
};

export default BackgroundTheme;
