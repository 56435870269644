import React from 'react';

export const NavbarSkeleton: React.FC = () => <div className="h-[46px] sm:h-[70px] bg-gray-300/20" />;

export const AvatarSkeleton: React.FC = () => (
  <div className="w-32 h-32 bg-gray-300 rounded-full animate-pulse" />
);

export const ButtonsSkeleton: React.FC = () =>
  Array.from({ length: 4 }).map((_, index) => (
    <div key={index} className="flex justify-center items-center">
      <div className="w-64 h-16 bg-gray-300 rounded-full animate-pulse" />
    </div>
  ));
