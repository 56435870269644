'use client';

import { useScreenSize } from '@/hooks';
import { initFacebookPixel } from '@/utils/analytics';
import { usePathname } from 'next/navigation';
import React, { FunctionComponent, Suspense, useEffect } from 'react';
import { Column } from '@/components/Column';
import Loading from './loading';
import { VendorProvider, useVendorContext } from './vendorContext';
import { AgoraLinkTypeE } from '@/types/cyclone/models';
import BackgroundTheme from './components/BackgroundTheme';
import { ButtonsSkeleton, NavbarSkeleton } from './components/Skeleton';

const NavbarStoreFront = React.lazy(() => import('./components/NavbarStoreFront/NavbarStoreFront'));
const NavigationStoreFront = React.lazy(
  () => import('./components/NavigationStoreFront/NavigationStoreFront')
);
const FooterStoreFront = React.lazy(() => import('./components/FooterStoreFront/FooterStoreFront'));
const FloatingWhatsappButton = React.lazy(
  () => import('./components/FloatingWhatsappButton/FloatingWhatsappButton')
);

export type VendorLayoutProps = {
  children: React.ReactNode;
};

const VendorLayoutContent: FunctionComponent<VendorLayoutProps> = ({ children }) => {
  const path = usePathname();
  const { isMobile } = useScreenSize();
  const { vendor, vendorTheme, isLoading } = useVendorContext();

  let splitPath = path ? path.split('/')[1] : undefined;
  if (splitPath === 'iframe') splitPath = path?.split('/')[2];
  const username = splitPath !== 'checkout' ? splitPath : undefined;

  useEffect(() => {
    if (vendor && vendor.facebook_pixel_id) {
      initFacebookPixel(vendor.facebook_pixel_id);
    }
  }, [vendor]);

  const isLandingPage = path === `/${username}/`;
  const isAgenda = path === `/${username}/agenda`;
  const showFooter = isMobile || !isAgenda;
  const isFullScreen = path === `/${username}/reservar`;

  const vendorAgoraLinks =
    vendor?.vendor_links
      ?.filter(
        (link) => link.is_agora_link && !link.is_hidden && link.agora_link_type !== AgoraLinkTypeE.MAPS
      )
      .sort((a, b) => a.order - b.order) || [];

  if (isFullScreen)
    return (
      <main>
        <Column className="w-full min-h-screen">{children}</Column>
      </main>
    );

  return (
    <main>
      <Suspense fallback={<Loading />}>
        <BackgroundTheme>
          {/* Navbar */}
          <Suspense fallback={<NavbarSkeleton />}>
            <NavbarStoreFront vendor={vendor} vendorLinks={vendorAgoraLinks} />
          </Suspense>

          {/* Main Content */}
          {!isLoading && <Column className="w-full min-h-screen">{children}</Column>}

          {/* Navigation Store Front for Mobile */}
          {vendorAgoraLinks.length > 0 && isMobile && !isLandingPage && (
            <Suspense fallback={<ButtonsSkeleton />}>
              <NavigationStoreFront vendor={vendor} vendorLinks={vendorAgoraLinks} />
            </Suspense>
          )}

          {/* Footer */}
          {vendor && showFooter && (
            <Suspense fallback={null}>
              <FooterStoreFront vendor={vendor.username} vendorTheme={vendorTheme} />
            </Suspense>
          )}

          {/* Floating Whatsapp Button */}
          {vendor && vendor.user.phone && (
            <Suspense fallback={null}>
              <FloatingWhatsappButton vendorNumber={vendor.user.phone} />
            </Suspense>
          )}
        </BackgroundTheme>
      </Suspense>
    </main>
  );
};

const VendorLayout: FunctionComponent<VendorLayoutProps> = (props) => {
  const path = usePathname();
  let splitPath = path ? path.split('/')[1] : undefined;
  if (splitPath === 'iframe') splitPath = path?.split('/')[2];
  const username = splitPath !== 'checkout' ? splitPath : undefined;

  return (
    <VendorProvider username={username}>
      <VendorLayoutContent {...props} />
    </VendorProvider>
  );
};

export default VendorLayout;
