import React from 'react';
import { AvatarSkeleton, ButtonsSkeleton, NavbarSkeleton } from './components/Skeleton';

const Loading: React.FC = () => (
  <div>
    {/* Navbar */}
    <NavbarSkeleton />

    {/* Avatar */}
    <div className="container mx-auto mb-8 -mt-10">
      <div className="flex justify-center">
        <AvatarSkeleton />
      </div>
    </div>

    {/* Buttons */}
    <div className="container mx-auto my-8">
      <div className="flex flex-col gap-6 justify-center">
        <ButtonsSkeleton />
      </div>
    </div>
  </div>
);

export default Loading;
