// VendorContext.tsx
import { createContext, useContext, ReactNode } from 'react';
import { AgoraThemeI, VendorI } from '@/types/cyclone/models';
import { GetVendorI } from '@/types/cyclone/requests';
import { useQuery } from '@tanstack/react-query';
import { useClient } from '@/hooks/useClient';

interface VendorContextValue {
  vendor?: VendorI;
  vendorTheme?: AgoraThemeI;
  isLoading: boolean;
}

const VendorContext = createContext<VendorContextValue>({
  vendor: undefined,
  vendorTheme: undefined,
  isLoading: true
});

export const useVendorContext = () => useContext(VendorContext);

interface VendorProviderProps {
  children: ReactNode;
  username?: string;
}

export const VendorProvider = ({ children, username }: VendorProviderProps) => {
  const { client } = useClient();
  const { data: vendor, isLoading } = useQuery(
    ['vendor', username],
    async () => await client<GetVendorI>(`vendors/soft/${username}`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!username
    }
  );

  const getTheme = (): AgoraThemeI | undefined => {
    if (vendor && vendor.vendor_storefront) {
      return {
        name: vendor.vendor_storefront.template_name,
        colors: {
          title: vendor.vendor_storefront.title_color,
          background: vendor.vendor_storefront.background_color,
          text: vendor.vendor_storefront.text_color,
          button: vendor.vendor_storefront.button_color,
          shadow: vendor.vendor_storefront.shadow_color
        },
        pattern_type: vendor.vendor_storefront?.pattern_type
      };
    } else return undefined;
  };

  const value: VendorContextValue = {
    vendor,
    vendorTheme: getTheme(),
    isLoading
  };

  return <VendorContext.Provider value={value}>{children}</VendorContext.Provider>;
};
